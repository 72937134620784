import { computed } from 'vue'
import type { Ref } from 'vue'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~~/tailwind.config'
import { getBaseChartConfig } from '~/components/charts/chartConfig'
import { useCathodeStore } from '~/stores/cathode'

const fullConfig = resolveConfig(tailwindConfig)

export interface ChartJSRenderProps {
  chartId: string
  title?: string
  isStacked?: boolean
  hasCustomTooltip?: boolean
  tooltipTitleIsDate?: boolean
  tooltipPostfix?: string
  xScaleLabel?: string
  yScaleLabel?: string
  yAxisPostfix?: string
  totalMultiplicate?: number
  verticalXAxesTicks?: boolean
  maxTicksLimit?: number
  gridLinesColor?: string
  yScaleTextColor?: string
  thousandfoldYLabel?: boolean
  priceRange?: boolean
  excludeLabelsToTotal?: any[]
  maxXScaleValue?: number
  maxYScaleValue?: number
  maxYValue?: number
  thousandfoldXLabel?: boolean
  hideYLabel?: boolean
  startXFromZero?: boolean
  hasFixedXScale?: boolean
  hasTooltipValueProp?: boolean
  thermometer?: boolean
  showPercentageStackedBar?: boolean
}

export const defaultProps = {
  title: '',
  isStacked: false,
  hasCustomTooltip: false,
  tooltipTitleIsDate: false,
  tooltipPostfix: '',
  xScaleLabel: '',
  yScaleLabel: '',
  yAxisPostfix: '',
  totalMultiplicate: 1,
  verticalXAxesTicks: false,
  maxTicksLimit: 0,
  gridLinesColor: fullConfig.theme.colors['chart-gride-line-color'],
  yScaleTextColor: '',
  thousandfoldYLabel: false,
  priceRange: false,
  excludeLabelsToTotal: () => [],
  maxXScaleValue: 0,
  maxYScaleValue: 0,
  maxYValue: null,
  thousandfoldXLabel: false,
  hideYLabel: false,
  startXFromZero: false,
  hasFixedXScale: false,
  hasTooltipValueProp: false,
  thermometer: false,
  showPercentageStackedBar: false,
}

interface ChartJSRenderAditionalProps {
  indexAxis: Ref<string>
  pluginCrosshairEnabled: Ref<boolean>
  showTotal: Ref<boolean>
  yAxisMax
  horizontal
  drawXScale: () => void
}

const useChartJSRender = (
  props: ChartJSRenderProps,
  adtionalProps: ChartJSRenderAditionalProps,
) => {
  const intViewportWidth = ref(0)
  const cathodeStore = useCathodeStore()

  const animation = computed(() => ({
    onComplete: () => {
      if (props.hasFixedXScale) adtionalProps.drawXScale()
    },
  }))

  const config = ref()

  const getCurrentConfig = () =>
    getBaseChartConfig({
      showLegend: false,
      indexAxis: adtionalProps.indexAxis.value,
      pluginCrosshairEnabled: adtionalProps.pluginCrosshairEnabled.value,
      showTotal: adtionalProps.showTotal.value,
      yAxisMax: adtionalProps.yAxisMax,
      horizontal: adtionalProps.horizontal,
      chartId: props.chartId,
      title: props.title,
      hasCustomTooltip: props.hasCustomTooltip,
      isStacked: props.isStacked,
      yAxisPostfix: props.yAxisPostfix,
      yScaleLabel: props.yScaleLabel,
      xScaleLabel: props.xScaleLabel,
      tooltipPostfix: props.tooltipPostfix,
      tooltipTitleIsDate: props.tooltipTitleIsDate,
      yScaleTextColor: props.yScaleTextColor,
      totalMultiplicate: props.totalMultiplicate,
      thousandfoldXLabel: props.thousandfoldXLabel,
      hideYLabel: props.hideYLabel,
      startXFromZero: props.startXFromZero,
      verticalXAxesTicks: props.verticalXAxesTicks,
      maxTicksLimit: props.maxTicksLimit,
      gridLinesColor: props.gridLinesColor,
      priceRange: props.priceRange,
      excludeLabelsToTotal: props.excludeLabelsToTotal,
      maxXScaleValue: props.maxXScaleValue,
      maxYScaleValue: props.maxYScaleValue,
      thousandfoldYLabel: props.thousandfoldYLabel,
      hasTooltipValueProp: props.hasTooltipValueProp,
      thermometer: props.thermometer,
      scaleTextColor: cathodeStore.scaleTextColor,
      xAlign: props.hideYLabel || props.thermometer ? 'left' : undefined,
      yAlign: props.hideYLabel ? 'top' : undefined,
      maxYValue: props.maxYValue,
      showPercentageStackedBar: props.showPercentageStackedBar,
      fontSizeY: cathodeStore.scaleFontSizeY,
      fontSizeX: cathodeStore.scaleFontSizeX,
      titleFontSize: cathodeStore.scaleTitleFontSize,
    })

  const options = computed(() => {
    if (!config.value) {
      config.value = getCurrentConfig()
      return {
        ...config.value,
        animation: animation.value,
        intViewportWidth: intViewportWidth.value,
      }
    }

    if (props.chartId === cathodeStore.selectedChartIdDownloadToImage) {
      return {
        ...getCurrentConfig(),
        animation: animation.value,
        intViewportWidth: intViewportWidth.value,
      }
    }

    return {
      ...config.value,
      animation: animation.value,
      intViewportWidth: intViewportWidth.value,
    }
  })

  return { options, intViewportWidth }
}

export default useChartJSRender
